<template>
  <div class="">
    <a-spin :spinning="loading">
      <div class="flex space alcenter">
        <div class="ft20 cl-main ftw600">{{getTitle}}</div>
        <a-button @click="$router.go(-1)">返回</a-button>
      </div>

      <div class="mt20 bg-w pd40">

        <div style="width: 1000px;">

          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">

            <a-row>
              <a-col :span="12">
                <a-form-model-item required label="活动名称">
                  <a-input v-model="form.name" placeholder="请输入活动名称" style="width:350px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="活动分类">
                  <a-tree-select v-model="form.active_cid" style="width:220px" :tree-data="catesdata"
                                 placeholder="请选择活动分类" >
                  </a-tree-select>

                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="活动地址" >
                  <a-cascader style="width: 220px;"
                      v-model="form.domiciles"
                      :options="regiondata"
                      :default-value="form.domiciles"
                      placeholder="请选择活动地址"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="活动详址">
                  <a-input v-model="form.address" placeholder="请输入活动详细地址" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="状态" >
                  <a-radio-group v-model="form.status">
                    <a-radio :value="0">禁用</a-radio>
                    <a-radio :value="1">启用</a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="签到送积分" >
                  <a-input-number v-model="form.exchange_integral" placeholder="填0为不赠送积分" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item required label="签到范围">
                  <a-input-number v-model="form.distance" placeholder="点选范围多少米以内" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item required label="报名上限">
                  <a-input v-model="form.add_count" placeholder="请输入报名人数上限" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item required label="联系人">
                  <a-input v-model="form.contacts" placeholder="请输入联系人" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
              <a-col :span="12">
                <a-form-model-item label="联系电话">
                  <a-input v-model="form.contacts_mobel" placeholder="请输入联系电话" :precision="0" :min="0" style="width: 220px;"/>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="活动时间" >
                  <a-range-picker
                      :show-time="{ format: 'HH:mm' }"
                      format="YYYY-MM-DD HH:mm"
                      :placeholder="['活动开始时间', '活动结束时间']"
                      @ok="onOkAct"
                      @change="changeAct"
                      v-model="form.active_time"
                  />
                </a-form-model-item>
              </a-col>

              <a-col :span="12">
                <a-form-model-item label="报名时间" >
                  <a-range-picker
                      :show-time="{ format: 'HH:mm' }"
                      format="YYYY-MM-DD HH:mm"
                      :placeholder="['报名开始时间', '报名结束时间']"
                      @ok="onOkAdd"
                      @change="changeAdd"
                      v-model="form.active_add_time"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="签到时间" >
                  <a-range-picker
                      :show-time="{ format: 'HH:mm' }"
                      format="YYYY-MM-DD HH:mm"
                      :placeholder="['签到开始时间', '签到结束时间']"
                      @ok="onOkSign"d
                      @change="changeSign"
                      v-model="form.active_sign_time"
                  />
                </a-form-model-item>
              </a-col>

              <a-col :span="12">
                <a-form-model-item required label="签到定位">
                  <div class="flex alcenter">
                    <a-input :value="form.lat + ',' + form.lng" disabled style="width: 200px; margin-right: 10px; color: #000000;"></a-input>
                    <baidu-map :lat="form.lat" :lng="form.lng" @select="selectAct"></baidu-map>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="12">
                <a-form-model-item label="活动主图" >
                  <div class="ft12 ftw400 cl-notice"></div>
                  <div class="mt10 ml20">
                    <upload-img v-model="form.cover_img"></upload-img>
                  </div>
                </a-form-model-item>
              </a-col>
            </a-row>

            <a-row>
              <a-col :span="2"><label class="ant-form-item" style="color: rgba(0, 0, 0, 0.85)">活动介绍</label></a-col>
              <a-col :span="22">
                <html-edit v-model="form.detail"></html-edit>
              </a-col>
            </a-row>


          </a-form-model>

        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import uploadImg from '@/components/upload/upload.vue';
import  htmlEdit from '@/components/html.vue';
import BaiduMap from '@/components/map/baidumap.vue';

export default{
  components:{
    uploadImg,
    htmlEdit,
    BaiduMap
  },
  data(){
    return {
      loading:false,
      confirmLoading:false,
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      id:0,
      regiondata:[],
      catesdata:[],
      form: {
        lat:'',
        lng:'',
      }
    }
  },
  created() {
    if (!this.$route.query.id) {
      this.id=0;
    } else {
      this.id = parseInt(this.$route.query.id);
    }
    this.loaddata();
  },
  computed:{
    getTitle(){
      if(this.id==0){
        return '添加活动';
      }else{
        return '活动详情';
      }
    }
  },
  methods:{
    loaddata(){
      if(this.loading==true) return;
      this.loading=true;

      this.$http.api('admin/region',{}).then(res=>{
        this.regiondata=res.lists;
      }).catch(res=>{
        this.$router.go(-1);
      });

      this.$http.api('platform/active/showActivity',{
        id:this.id
      }).then(res=>{
        if(res.detail){
          this.form=res.detail;
        }
        this.catesdata=res.cates;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
        this.$router.go(-1);
      })

    },

    selectAct(e){
      this.form.lat = e.lat;
      this.form.lng = e.lng;
    },
    onOkSign(value) {
      console.log('onOkSign: ', value);
    },
    changeSign(date, dateString){
      this.form.active_sign_time = dateString;
    },
    onOkAdd(value) {
      console.log('onOkAdd: ', value);
    },
    changeAdd(date,dateString){
      this.form.active_add_time = dateString;
    },
    onOkAct(value) {
      console.log('onOkAct: ', value);
    },
    changeAct(date,dateString){
      this.form.active_time = dateString;
    },
  }
}
</script>

<style>
.edit-project-line{
  width: 100%;
  height: 1px;
  background: #EBEDF5;
}

.edit-project-details-text {
  width: 120px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #5333FF;
}

.mobile-content{
  width: 750px;
}
.mobile-content{

}

.mobile-item{
  position: relative;
}
.mobile-item .action{
  position: absolute;
  left: 0;
  top: -15px;
  width: 100%;
  z-index: 2;
}
.del-action{
  width: 30px;
  height: 30px;
  background: rgba(0,0,0,.5);
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color:#FFFFFF;
  position: absolute;
  right: -15px;
  top:-15px;
  z-index: 3;
}
.up-action,.down-action{
  width: 30px;
  height: 30px;
  background: #FFFFFF;
  text-align: center;
  line-height: 30px;
  border-radius: 15px;
  color:#007AFF;
  box-shadow:0px 2px 8px 0px rgba(0,0,0,0.08);

}
</style>